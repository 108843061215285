<template>
  <div id="content">
    <div class="container">
      <h2>Döntő végeredmények</h2>
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>#</th>
            <th>Csapatnév</th>
            <th><strong>Összesen</strong></th>
            <th>6v6</th>
            <th>1v1</th>
            <th>Liga #1</th>
            <th>Liga #2</th>
            <th>Liga #3</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(result,index) in results.final_second">
            <tr>
              <td>{{(index+1)}}.</td>
              <td>{{result.teamName}}</td>
              <td>{{result.sumScore}}</td>
              <td>{{result.finalScore1}}</td>
              <td>{{result.finalScore2}}</td>
              <td>{{result.finalScore3}}</td>
              <td>{{result.finalScore4}}</td>
              <td>{{result.finalScore5}}</td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
      <h2>Döntő első futtatás</h2>
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>#</th>
            <th>Csapatnév</th>
            <th><strong>Összesen</strong></th>
            <th>1/1</th>
            <th>1/2</th>
            <th>2/1</th>
            <th>2/2</th>
            <th>3/1</th>
            <th>3/2</th>
            <th>4/1</th>
            <th>4/2</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(result,index) in results.final_first">
            <tr>
              <td>{{result.rank}}.</td>
              <td>{{result.teamName}}</td>
              <td>{{result.finalScore1}}</td>
              <td>{{getValue(result.runs,1238)}}</td>
              <td>{{getValue(result.runs,1241)}}</td>
              <td>{{getValue(result.runs,1242)}}</td>
              <td>{{getValue(result.runs,1243)}}</td>
              <td>{{getValue(result.runs,1244)}}</td>
              <td>{{getValue(result.runs,1245)}}</td>
              <td>{{getValue(result.runs,1246)}}</td>
              <td>{{getValue(result.runs,1247)}}</td>

            </tr>
          </template>
          </tbody>
        </table>
      </div>
      <h2>Elődöntő eredmények</h2>
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>#</th>
            <th>Csapatnév</th>
            <th></th>
            <th></th>
            <th><strong>Összesen</strong></th>
            <th>1.</th>
            <th>2.</th>
            <th>3.</th>
            <th>4.</th>
            <th>5.</th>
            <th>6.</th>
            <th>7.</th>
            <th>8.</th>
            <th>9.</th>
            <th>10.</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(result,index) in results.semifinal_live">
            <tr>
              <td>{{result.rank}}.</td>
              <td>{{result.teamName}}</td>
              <td>{{result.teamType}}</td>
              <td>{{result.university}}</td>
              <td><strong>{{result.sumScore}}</strong></td>
              <td>{{result.sll_1}} <span class="small">({{result.sllo_1}})</span></td>
              <td>{{result.sll_2}} <span class="small">({{result.sllo_2}})</span></td>
              <td>{{result.sll_3}} <span class="small">({{result.sllo_3}})</span></td>
              <td>{{result.sll_4}} <span class="small">({{result.sllo_4}})</span></td>
              <td>{{result.sll_5}} <span class="small">({{result.sllo_5}})</span></td>
              <td>{{result.sll_6}} <span class="small">({{result.sllo_6}})</span></td>
              <td>{{result.sll_7}} <span class="small">({{result.sllo_7}})</span></td>
              <td>{{result.sll_8}} <span class="small">({{result.sllo_8}})</span></td>
              <td>{{result.sll_9}} <span class="small">({{result.sllo_9}})</span></td>
              <td>{{result.sll_10}} <span class="small">({{result.sllo_10}})</span></td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
      <h2>Elődöntő teszt eredmények</h2>
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>#</th>
            <th>Csapatnév</th>
            <th></th>
            <th></th>
            <th><strong>Összesen</strong></th>
            <th>1.</th>
            <th>2.</th>
            <th>3.</th>
            <th>4.</th>
            <th>5.</th>
            <th>6.</th>
            <th>7.</th>
            <th>8.</th>
            <th>9.</th>
            <th>10.</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(result,index) in results.semifinal_test">
            <tr>
              <td>{{result.rank}}.</td>
              <td>{{result.teamName}}</td>
              <td>{{result.teamType}}</td>
              <td>{{result.university}}</td>
              <td><strong>{{result.sumScore}}</strong></td>
              <td>{{result.stl_1}} <span class="small">({{result.stlo_1}})</span></td>
              <td>{{result.stl_2}} <span class="small">({{result.stlo_2}})</span></td>
              <td>{{result.stl_3}} <span class="small">({{result.stlo_3}})</span></td>
              <td>{{result.stl_4}} <span class="small">({{result.stlo_4}})</span></td>
              <td>{{result.stl_5}} <span class="small">({{result.stlo_5}})</span></td>
              <td>{{result.stl_6}} <span class="small">({{result.stlo_6}})</span></td>
              <td>{{result.stl_7}} <span class="small">({{result.stlo_7}})</span></td>
              <td>{{result.stl_8}} <span class="small">({{result.stlo_8}})</span></td>
              <td>{{result.stl_9}} <span class="small">({{result.stlo_9}})</span></td>
              <td>{{result.stl_10}} <span class="small">({{result.stlo_10}})</span></td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
      <h2>Selejtező eredményei</h2>
      <p class="mb-3">Az első 40 helyezett csapat jut tovább, és összesen minimum 100 pontot kell elérni, feladatonként minimum 5 pontot.</p>
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>#</th>
            <th>Csapatnév</th>
            <th></th>
            <th></th>
            <th>Dobolás</th>
            <th>Sakk</th>
            <th>Szókereső</th>
            <th>Összesen</th>
            <th>Státusz</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(result,index) in results.qualifier">
            <tr v-if="result.finalLine===true" class="bg-danger text-white">
              <td colspan="9">
                Az az első 40 csapat jut tovább az elődöntőbe, akik teljesítik a feltélteleket (min. 100 pont, feladatonként min. 5 pont).
              </td>
            </tr>
            <tr :class="result.status===1 ? 'bg-success text-white' : ''">
              <td>{{result.rank}}.</td>
              <td>{{result.teamName}}</td>
              <td>{{result.teamType}}</td>
              <td>{{result.university}}</td>
              <td>{{result.qualScore_1}}</td>
              <td>{{result.qualScore_2}}</td>
              <td>{{result.qualScore_3}} <span class="small">({{result.qualScore_3_origin}})</span></td>
              <td>{{result.sumScore}}</td>
              <td v-if="result.status===1" class="small">elődöntőbe jut</td>
              <td v-else-if="result.status===2 && result.sumScore>0" class="small">feladatonként 5 pontot kell elérni</td>
              <td v-else></td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Results',
  props:['globalData','pageId'],
  data(){
    return{
      results : this.globalData.init.results
    }
  },
  methods:{
    getValue(runs,runId){
      if(runs.hasOwnProperty(runId)){
        return runs[runId]['VP']+' ('+runs[runId]['score']+')'
      }else{
        return 0;
      }
    }
  }
}
</script>
<style scoped>
.container{
  max-width: 1400px;
}
</style>
